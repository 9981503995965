import Vue from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import {
  required,
  maxLength,
  numeric
} from "vuelidate/lib/validators";
import { withParams } from 'vuelidate/lib';
const isValidGender = withParams(
  { type: 'isValidGender' },
  value => {
    const validValues = [1, 2];
    return validValues.includes(parseInt(value));
  }
);
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";
import store from '../../../store';

export default {
  name: "StudentEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config: {
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
      },
      isLoading: false,
      disabled: false,
      pwd_change: false,
      studentData: {},
      gradeFeeData: {},
      parentData: {},
      entry_no: '',
      dob: '',
      join_date: '',
      gradeArr: [],
      grade: '',
      religion: '',
      campus_id: '',
      section: '',
      student_image: '',
      selectedGrade: 0,
      selectedSection: 0,
      selectedReligion: 0,
      gender: null,
      religionArr: null,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      sectionArr: [{
        id: 0,
        text: 'Choose Section'
      }],
      sectionArrMain: [{
        id: 0,
        text: 'Choose Section'
      }],
      submitted: false,
      noSectionMsg: '',
      hidden: false,
      tmpResult: false,
      attendedClass: false,
      attendedClassMain: false,
      classes: [],
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoadingWebcam: false,
      link: '#',
      total_annual: 0,
      total_monthly: 0,
      tmp_input: [],
      mainClass: {},
      inputData: {
        campus_grade_id: null,
        class_id: null,
        id: null,
        section: '',
        annual_fee: 0,
        monthly_fee: 0,
        stu_grade_id: null,
      },
    };
  },

  validations: {
    mainClass: {
      class_id: {
        required
      },
      grade_id: {
        required
      }
    },
    religion: {
      required
    },
    studentData: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      dob: {
        required,
        maxLength: maxLength(100)
      },
      join_date: {
        maxLength: maxLength(100)
      },
      entry_no: {
        maxLength: maxLength(20)
      },
      gender: {
        isValidGender
      },
      nationality: {
        maxLength: maxLength(100)
      },
      weight: {
        maxLength: maxLength(20)
      },
      height: {
        maxLength: maxLength(20)
      },
      sibling: {
        numeric,
        maxLength: maxLength(2)
      },
      hobby: {
        maxLength: maxLength(1000)
      },
      allergic_food: {
        maxLength: maxLength(1000)
      },
      special_topic: {
        maxLength: maxLength(1000)
      },
      blood: {
        maxLength: maxLength(20)
      },
      previous_school: {
        maxLength: maxLength(200)
      },
      // ferry_status: {
      //   required
      // },
    },
  },

  computed: {
    ...mapGetters([
      'getUser',
      'studentNo'
    ]),
  },

  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoadingWebcam = true;

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const constraints = (window.constraints = {
          audio: false,
          video: true
        });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoadingWebcam = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error);
            this.isLoadingWebcam = false;
            alert("May the browser didn't support or there is some errors.");
          });
      } else {
        this.isLoadingWebcam = false;
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        alert("Sorry, your browser or device does not support accessing the camera. Please try using a different browser or device.");
      }
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      this.student_image = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    getGradeFee(gradeId, request, type) {
      axios.get("getGradeFee/" + gradeId, request)
        .then(res => {
          // this.gradeFeeData = res.data[0];
          if (type == 'main') {
            this.mainClass.annual_fee = res.data[0].annual_fee;
            this.mainClass.monthly_fee = res.data[0].monthly_fee;
          } else {
            this.inputData.annual_fee = res.data[0].annual_fee;
            this.inputData.monthly_fee = res.data[0].monthly_fee;
          }
          this.gradeFeeData = res.data[0] ?? { 'annual_fee': '', 'monthly_fee': '' };
        }).catch((error) => {
          if (error.response && error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    onProfileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = e => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.student_image = e.target.result;
            }
          };
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg() {
      this.student_image = '';
      document.getElementById('studentImage').value = '';
    },

    changeEvent(val, fieldName, type = null) {
      this.$set(this, fieldName, val);
      this.attendedClass = false;
      if (fieldName == 'grade') {
        var request = {
          params: {
            campus_id: store.state.user.campus_id,
          }
        }

        if (type == 'main' && val != 0) {
          this.attendedClassMain = this.classes.some(obj => obj.grade_id == this.mainClass.grade_id);
        }

        if (val == 0 && type == 'main') {
          this.attendedClassMain = false;
          this.sectionArrMain = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.noSectionMsg = '';
          this.isLoading = false;
        } else if (val == 0 && type == null) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.noSectionMsg = '';
          this.isLoading = false;
          this.gradeFeeData = { 'annual_fee': '', 'monthly_fee': '' };
        } else {
          this.getSection(val, request, 0, type);
          this.getGradeFee(val, request, type);
        }
      }

      if (fieldName == 'section' && type == 'main') {
        this.mainClass.class_id = val;
        this.mainClass.section = this.sectionArrMain.find(e => e.id == val)?.text || '';
      } else if (fieldName == 'section' && type == null) {
        this.inputData.class_id = val;
        this.inputData.section = this.sectionArr.find(e => e.id == val)?.text || '';
      }
    },

    getSection(gradeId, request, selectedClassId, type = null) {

      axios.get("/class/getSection/" + gradeId, request).then((response) => {
        if (response.data[0].length > 0) {
          if (type == 'main') {
            this.sectionArrMain = [{
              "id": 0,
              "text": "Choose Section"
            }].concat(response.data[0]);
            this.mainClass.class_id = selectedClassId;
          } else {
            this.sectionArr = [{
              "id": 0,
              "text": "Choose Section"
            }].concat(response.data[0]);
          }
          this.noSectionMsg = '';
          this.isLoading = false;
        } else {
          if (type == 'main') {
            this.noSectionMsg = 'Please create class to show section.';
            this.sectionArrMain = [{
              "id": 0,
              "text": "Choose Section"
            }].concat(response.data[0]);
            this.mainClass.class_id = 0;
          } else {
            this.sectionArr = [{
              "id": 0,
              "text": "Choose Section"
            }].concat(response.data[0]);
          }
          this.isLoading = false;
        }
      })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    async getStudentEdit() {
      try {
        var request = {
          params: {
            campus_id: store.state.user.campus_id
          }
        }

        const response = await axios.get("/student/detail/" + this.$route.params.id, request)
        if (response.data) {
          this.studentData = response.data;
          this.student_image = this.studentData.profile_path;
          this.grade = this.studentData.grade_id;
          this.mainClass = this.studentData.classes[0];
          this.classes = this.studentData.classes;
          this.classes.splice(0, 1);
          this.selectedReligion = this.religion = this.studentData.religion;

          var request1 = {
            params: {
              campus_id: store.state.user.campus_id,
              academic_year: this.studentData.acd_year_id
            }
          }
          // this.getFerryStatusById(this.studentData.student_id);
          this.getSection(this.mainClass.grade_id, request1, this.mainClass.class_id, 'main');
          // this.getGradeFee(this.studentData.grade_id, request1);
          this.calculateFee();
          this.getParentDetail(this.studentData.parent_id);
          this.getGradeName();
        }

      } catch (error) {
        console.log(error);
        if (error.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Student Detail API Error", error);
      }
    },

    getParentDetail(parentId) {
      axios.get("/parent/detail/" + parentId)
        .then(response => {
          this.parentData = response.data[0];
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Parent Detail API Error", error);
        });
    },

    getGradeName() {
      var academicYearId = this.studentData.acd_year_id;
      axios.get('getGradeNameByAcademicYear?id=' + academicYearId, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "Select"
        }];
        this.gradeArr = $defaultSelectOption.concat(res.data);
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    // getFerryStatusById(id) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('getFerryStatusById/' + id, {
    //       params: {
    //         user_type: 'student'
    //       }
    //     })
    //       .then(res => {
    //         this.studentData['ferry_status'] = res.data.ferry_status;
    //         this.isLoading = false;
    //         resolve();
    //       })
    //       .catch(error => {
    //         if (error.response && error.response.status === 401) {
    //           this.$store.commit('logout');
    //           window.location.replace('login');
    //         }
    //         this.isLoading = false;
    //         reject(error);
    //       });
    //   });
    // },

    confirmUpdate() {
      this.submitted = true;
      if (this.$v.$invalid || this.mainClass.class_id == 0 || this.mainClass.grade_id == 0 || this.attendedClassMain || this.religion == 0 || this.religion == '') {

        return;
      }
      this.update();
    },

    update() {
      this.disabled = true;
      this.classes.splice(0, 0, this.mainClass);
      var request = {
        parent_id: this.parentData.id,
        name: this.studentData.name,
        student_image: this.student_image,
        student_image_old: this.studentData.profile_path,
        c_student_no: this.studentData.c_student_no,
        dob: this.studentData.dob,
        start_date: this.studentData.join_date,
        entry_no: this.studentData.entry_no,
        gender: this.studentData.gender,
        result: this.studentData.result,
        nationality: this.studentData.nationality,
        religion: this.religion,
        weight: this.studentData.weight,
        blood: this.studentData.blood,
        height: this.studentData.height,
        sibling: this.studentData.sibling,
        hobby: this.studentData.hobby,
        allergic_food: this.studentData.allergic_food,
        previous_school: this.studentData.previous_school,
        special_topic: this.studentData.special_topic,
        campus_id: store.state.user.campus_id,
        // ferry_status: this.studentData.ferry_status,
        updated_id: store.state.user.id,
        classes: this.classes,
      }

      axios.post("/student/update/" + this.$route.params.id, request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Student Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "studentList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 422 && error.response.data.message) {
            this.$notification.error(error.response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.disabled = false;
          console.log("Student Update API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/student/list"
      });
    },

    back() {
      this.$router.go(-1);
    },

    calculateFee() {
      this.total_annual = this.classes.reduce((total, cls) => total + cls.annual_fee, 0);
      this.total_monthly = this.classes.reduce((total, cls) => total + cls.monthly_fee, 0);
    },

    reset() {
      this.resetData();
    },

    resetData() {
      this.tmpResult = false;
      this.inputData.grade_id = 0;
      this.inputData.class_id = null;
      this.inputData.id = null;
      this.inputData.section = '';
      this.inputData.stu_grade_id = null;
      this.inputData.annual_fee = 0;
      this.inputData.monthly_fee = 0;
      this.changeEvent(this.inputData.grade_id, 'grade');
    },

    cloneForm() {
      this.tmpResult = false;
      // this.firstname_ErrMsg = '';
      this.tmp_input = [];
      this.hidden = true;
      if (this.inputData.class_id == null || this.inputData.class_id == 0 || this.inputData.grade_id == null || this.inputData.grade_id == 0) {
        this.tmpResult = true;
        return;
      }

      this.attendedClass = this.classes.some(classData => classData.grade_id == this.inputData.grade_id) || this.inputData.grade_id == this.mainClass.grade_id;
      if (this.attendedClass) {
        this.tmpResult = true;
        return;
      }
      this.classes.push(Vue.util.extend({}, this.inputData));
      this.resetData();
      this.tmpResult = false;
      this.calculateFee();
    },

    editClone(index) {
      this.hidden = true;
      this.addData();
      this.resetData();
      this.tmpInputData(this.classes[index]);
      this.inputData = this.classes[index];
      this.changeEvent(this.inputData.grade_id, 'grade');
      Vue.delete(this.classes, index);
      this.calculateFee();
    },

    addData() {
      if (this.inputData.campus_grade_id && this.inputData.section) {
        this.classes.push(Vue.util.extend({}, this.inputData));
      }
    },

    removeClone(index) {
      Vue.delete(this.classes, index);
      this.calculateFee();
    },

    tmpInputData(originalData) {
      this.tmp_input.campus_grade_id = originalData.campus_grade_id;
      this.tmp_input.id = originalData.id;
      this.tmp_input.class_id = originalData.class_id;
      this.tmp_input.section = originalData.section;
      this.tmp_input.annual_fee = originalData.annual_fee;
      this.tmp_input.monthly_fee = originalData.monthly_fee;
    },

    resetEdit() {
      this.resetEditData();
      this.resetData();
      this.hidden = false;
    },

    resetEditData() {
      if (this.tmp_input.campus_grade_id && this.tmp_input.class_id) {
        this.classes.push(Vue.util.extend({}, this.tmp_input));
      }
      this.tmp_input = [];
    },
  },
  mounted() {
    this.isLoading = true;
    this.getReligion();
    this.getStudentEdit();
  },
};
