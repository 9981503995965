import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import store from '../../../store';
import CONFIG from "@/assets/js/config.js";
import {
  required, maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "itemGradingEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      classData: {},
      selectedClass: 0,
      selectedYear: 0,
      classArr: [],
      studentNameArr: [],
      academic_year: '',
      academicYear: '',
      class_id: '',
      gradingSelected: 0,
      student_id: 0,
      month_id: 0,
      academicYearArr: null,
      gradingArr: null,
      submitted: false,
      requireErrMsg: [],
      msgCount: 0,
      commentMsg: [],
      cmt: 0,
      cmtCount: 0,
      itemGradingErrMsg: '',
      items: [],
      comment: [],
      secondNameErrMsg: [],
      secondCommentMsg: [],
      second_cmt: 0,
      isError: false,
      itemArr: [],
      reportCardMonth: [],
      itemGradingData: {},
      gradingInfo: {},
      remark: '',
    };
  },

  validations: {
    class_id: {
      required
    },
    academic_year: {
      required
    },
    student_id: {
      required
    },
    month_id: {
      required,
      maxLength: maxLength(100)
    },
    items: {
      $each: {
        required,
        maxLength: maxLength(10)
      }
    },
    comment: {
      $each: {
        maxLength: maxLength(1000)
      }
    }
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Academic Year'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.submitted = false;
      this.$set(this, fieldName, val);
      if (fieldName == "academic_year") {
        this.isLoading = true;
        this.class_id = 0;
        this.selectedClass = 0;
        this.selectedYear = 0;
        this.itemGradingErrMsg = '';
        this.studentNameArr = [];
        this.requireErrMsg = [];
        this.commentMsg = [];
        this.classArr = [];
        this.itemGradingData.forEach((value, index) => {
          this.itemGradingData[index]['grading'] = 0;
          this.itemGradingData[index]['grading_comment'] = '';
        });
        this.items = [];
        this.comment = [];
        this.secondNameErrMsg = [];
        this.secondCommentMsg = [];
        this.getClass(val);
      }
      if (fieldName == "class_id") {
        this.isLoading = true;
        this.student_id = '';
        this.itemGradingErrMsg = '';
        this.studentNameArr = [];
        this.requireErrMsg = [];
        this.commentMsg = [];
        this.itemGradingData.forEach((value, index) => {
          this.itemGradingData[index]['grading'] = 0;
          this.itemGradingData[index]['grading_comment'] = '';
        });
        this.items = [];
        this.comment = [];
        this.secondNameErrMsg = [];
        this.secondCommentMsg = [];
        this.getStudentName(val);
        this.getReportCardMonth();
      }
    },

    changeEventItem(val, index) {
      if (val === null || val === '') {
        this.requireErrMsg[index] = 'Please Enter Grading Mark.';
      } else {
        this.requireErrMsg[index] = '';
      }
    },

    changeEventItemForItem(val, index) {
      if (val === null || val === '') {
        this.secondNameErrMsg[index] = 'Please Enter Grading Mark.';
      } else {
        this.secondNameErrMsg[index] = '';
      }
    },

    async getGradingDetail() {
      try {
        const response = await axios.get("/itemGrading/detail/" + this.$route.params.id);
        if (response.data[0] != undefined) {
          this.gradingInfo = response.data[0];
          this.itemGradingData = response.data;
          this.selectedYear = this.gradingInfo.academic_year;
          this.student_id = this.gradingInfo.student_id;
          this.selectedClass = this.gradingInfo.class_id;
          this.class_id = this.gradingInfo.class_id;
          this.academic_year = this.gradingInfo.academic_year;
          this.gradingSelected = this.gradingInfo.grading;
          await this.getClass(this.selectedYear);
          await this.getStudentName(this.selectedClass);
          this.reportCardMonth.forEach((value) => {
            if (value.text == this.gradingInfo.month) {
              this.month_id = value.id;
            }

          });
          this.isLoading = false;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        console.log("Item Grading Detail API Error", error);
        this.isLoading = false;
      }
    },

    async getClass(yearId) {
      try {
        const response = await axios.get("class/classList/" + yearId, {
          params: {
            campus_id: store.state.user.campus_id
          }
        });

        if (response.data) {
          let defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];

          this.classArr = defaultSelectOption.concat(response.data);
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        console.error("Error fetching class list:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async getStudentName(classId) {
      try {
        const response = await axios.get("student/getStudentName/" + classId, {
          params: {
            campus_id: store.state.user.campus_id
          }
        });

        if (response.data) {
          let defaultSelectOption = [{
            'id': 0,
            'text': 'Select Student Name'
          }];
          this.studentNameArr = defaultSelectOption.concat(response.data);
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        console.error("Error fetching student names:", error);
      } finally {
        this.isLoading = false;
      }
    },

    getThisAcademicYear() {
      axios.get('period/getThisPeriod')
        .then(res => {
          this.academicYear = res.data;
          this.academic_year = this.academicYear.id;
          this.getClass(this.academic_year);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReportCardMonth() {
      axios.get('/itemGrading/getReportCardMonth')
        .then(res => {
          this.reportCardMonth = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    async getReportCardItemList() {
      axios
        .get("/reportCardItem/list")
        .then((response) => {
          if (response.data && response.data.data.length > 0) {
            this.itemArr = response.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Report Card Item API Error", error);
        });
    },

    confirmUpdate() {
      this.msgCount = 0;
      this.cmtCount = 0;
      this.submitted = true;
      this.isLoading = true;
      this.isError = false;
      if (
        this.$v.$invalid || this.class_id == '' || this.class_id == 0 ||
        this.academic_year == '' || this.academic_year == 0 || this.student_id == '' ||
        this.student_id == 0 || this.isError || this.month_id == '' || this.month_id == 0
      ) {
        if (this.itemGradingData.length > 0) {
          this.itemGradingData.forEach((value, index) => {
            this.cmt = this.itemGradingData[index]['grading_comment'] ? this.itemGradingData[index]['grading_comment'].length : 0;
            if (this.cmt > 1000) {
              this.commentMsg[index] = 'Grading Comment';
              this.cmtCount++;
            } else {
              this.commentMsg[index] = '';
            }
            if (this.itemGradingData[index]['grading'] == undefined || this.itemGradingData[index]['grading'] == 0) {
              this.requireErrMsg[index] = 'Please Enter Grading Mark.';
              this.msgCount++;
            } else if (this.itemGradingData[index]['grading'].length > 10) {
              this.requireErrMsg[index] = "Grading Mark must be at most 10 characters.";
              this.msgCount++;
            } else {
              this.requireErrMsg[index] = '';
            }
          });
          this.itemGradingErrMsg = '';
        } else {
          this.itemGradingErrMsg = "Report Card Item Data doesn't exist";
        }

        for (let index = this.itemGradingData.length; index < this.itemArr.length; index++) {
          this.second_cmt = this.comment[index] ? this.comment[index].length : 0;
          if (this.second_cmt > 1000) {
            this.secondCommentMsg[index] = 'Grading Comment';
            this.cmtCount++;
          } else {
            this.secondCommentMsg[index] = '';
          }
          if (this.items[index] == undefined || this.items[index] == 0) {
            this.secondNameErrMsg[index] = 'Please Enter Grading Mark.';
            this.msgCount++;
          } else if (this.items[index].length > 10) {
            this.secondNameErrMsg[index] = "Grading Mark must be at most 10 characters.";
            this.msgCount++;
          } else {
            this.secondNameErrMsg[index] = '';
          }
        }
        this.itemGradingErrMsg = '';

        if (this.msgCount > 0 || this.cmtCount > 0 || this.itemGradingErrMsg != '' || this.isError) {
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        return;
      } else {
        if (this.itemGradingData.length > 0) {
          this.itemGradingData.forEach((value, index) => {
            this.cmt = this.itemGradingData[index]['grading_comment'] ? this.itemGradingData[index]['grading_comment'].length : 0;
            if (this.cmt > 1000) {
              this.commentMsg[index] = 'Grading Comment';
              this.cmtCount++;
            } else {
              this.commentMsg[index] = '';
            }
            if (this.itemGradingData[index]['grading'] == undefined || this.itemGradingData[index]['grading'] == 0) {
              this.requireErrMsg[index] = 'Please Enter Grading Mark.';
              this.msgCount++;
            } else if (this.itemGradingData[index]['grading'].length > 10) {
              this.requireErrMsg[index] = "Grading Mark must be at most 10 characters.";
              this.msgCount++;
            } else {
              this.requireErrMsg[index] = '';
            }
          });
          this.itemGradingErrMsg = "";
        } else {
          this.itemGradingErrMsg = "Report Card Item Data doesn't exist";
        }
        for (let index = this.itemGradingData.length; index < this.itemArr.length; index++) {
          this.second_cmt = this.comment[index] ? this.comment[index].length : 0;
          if (this.second_cmt > 1000) {
            this.secondCommentMsg[index] = 'Grading Comment';
            this.cmtCount++;
          } else {
            this.secondCommentMsg[index] = '';
          }
          if (this.items[index] == undefined || this.items[index] == 0) {
            this.secondNameErrMsg[index] = 'Please Enter Grading Mark.';
            this.msgCount++;
          } else if (this.items[index].length > 10) {
            this.secondNameErrMsg[index] = "Grading Mark must be at most 10 characters.";
            this.msgCount++;
          } else {
            this.secondNameErrMsg[index] = '';
          }
        }
        this.itemGradingErrMsg = '';

        if (this.msgCount > 0 || this.cmtCount > 0) {
          this.isLoading = false;
          return;
        }
      }
      this.update();
    },

    update() {
      var request = {
        academic_year: this.academic_year,
        class_id: this.class_id,
        student_id: this.student_id,
        month_id: this.month_id,
        campus_id: store.state.user.campus_id,
        updated_id: store.state.user.id,
        grading: this.items,
        gradeComments: this.comment,
        reportCardItems: this.itemArr,
        itemGradingData: this.itemGradingData,
      }

      axios.post("/itemGrading/update/" + this.$route.params.id, request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.status == 200) {
            this.$notification.success("Item Grading Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "itemGradingList"
            });
          }
          if (response.data.status == 201) {
            this.isLoading = false;
            this.$notification.error("Item Grading Data Already Exist", {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Item Grading Edit API Error", error.errors);
          this.isLoading = false;
        });
    },

    showList() {
      this.$router.push({
        path: "/itemGrading/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getThisAcademicYear();
    this.getAcademicYear();
    this.getGradingDetail();
    this.getReportCardItemList();
    this.getReportCardMonth();
  },
};
