import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../../store';

export default {
  name: "ItemGradingList",
  components: {
    MainLayout,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      selectedYear: 0,
      selectedClass: 0,
      student_id: 0,
      academic_year: 0,
      month_id: 0,
      class_id: 0,
      academicYearArr: null,
      classArr: [],
      studentNameArr: [],
      itemGradingList: {},
      isLoading: false,
      search_status: false,
      reportCardMonth: [],
    };
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Academic Year'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
          this.getClass(this.selectedYear);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReportCardMonth() {
      axios.get('/itemGrading/getReportCardMonth')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Month'
          }];
          this.reportCardMonth = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      if (fieldName == "academic_year") {
        this.student_id = 0;
        this.selectedClass = 0;
        this.classArr = [];
        this.getClass(val);
      }
      if (fieldName == "class_id") {
        this.studentNameArr = [];
        this.student_id = 0;
        this.getStudentName(val);
      }
    },

    selectEvent({
      id
    }, fieldName) {
      id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
    },

    getClass($yearId) {
      axios.get("class/classList/" + $yearId, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          this.studentNameArr = [];
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getStudentName($classId) {
      axios.get("student/getStudentName/" + $classId, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Student Name'
          }];
          this.studentNameArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getItemGradingList(page = 1) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios
        .get("/itemGrading/list?page=" + page, request)
        .then((response) => {
          this.isLoading = false;
          this.search_status = false;
          this.itemGradingList = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Item Grading List API Error", error);
        });
    },

    reset() {
      this.selectedYear = 0;
      this.academic_year = 0;
      this.selectedClass = 0;
      this.class_id = 0;
      this.student_id = 0;
      this.month_id = 0;
      this.classArr = [];
      this.studentNameArr = [];
      this.reportCardMonth = [];
    },

    searcheItemGrading(page = 1) {
      this.isLoading = true;
      var request = {
        params: {
          academic_year: this.academic_year,
          class_id: this.class_id,
          student_id: this.student_id,
          month_id: this.month_id,
          campus_id: store.state.user.campus_id
        },
      };
      axios
        .get("/itemGrading/search?page=" + page, request)
        .then((response) => {
          this.search_status = true;
          this.itemGradingList = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Item Grading Search API Error", error);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/itemGrading/create"
      });
    },

    showDetail(id) {
      this.$router.push({
        name: "itemGradingDetail",
        params: {
          id: id,
        }
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "itemGradingEdit",
        params: {
          id: id,
        }
      });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteItemGrading(id);
      } else {
        console.log("no");
      }
    },

    deleteItemGrading(id) {
      this.isLoading = true;
      axios.post("/itemGrading/delete/" + id)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getItemGradingList();
          } else {
            this.$notification.error("Item Grading data is already deleted.", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "itemGradingList"
            });
            this.getItemGradingList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Item Grading Delete Error->", error.response.data);
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getItemGradingList();
    this.getAcademicYear();
    this.getReportCardMonth();
  },
};
