var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"nav"},[_c('a',{staticClass:"logo"},[_c('div',{staticClass:"logo-img"},[_c('router-link',{attrs:{"to":_vm.logoLink}},[_c('img',{attrs:{"src":require("@/assets/img/ncy_logo.png"),"alt":"Nyein Chan Yae Private High School"}})])],1),_c('div',{staticClass:"logo-text"},[_c('router-link',{attrs:{"to":_vm.logoLink}},[_c('p',[_vm._v("Nyein Chan Yae Private High School")])])],1)]),_c('div',{staticClass:"top-nav align-center",class:{ open: _vm.isMenuOpen }},[_c('div',{staticClass:"dropdown",on:{"click":_vm.toggleDropdown}},[_c('a',{staticClass:"dropdown-menu"},[_c('i',{staticClass:"fa-solid fa-user"}),_vm._v(" "+_vm._s(_vm._f("adminDisplayName")(this.$store.state.user.name))+" "),_vm._m(0)]),_c('ul',{staticClass:"dropdown-item dropdown_menu--animated dropdown_animation",class:{ show: _vm.isDropdownOpen }},[(_vm.id != '0001')?_c('li',[_c('router-link',{attrs:{"to":{
                                name: 'school',
                                params: { id: this.$store.state.user.campus_id },
                            }}},[_c('a',[_vm._v("Campus Info")])])],1):_vm._e(),(_vm.id == '0001')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'campusList' }}},[_c('a',[_vm._v("Campus")])])],1):_vm._e(),(_vm.id == '0001')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'adminList' }}},[_c('a',[_vm._v("Admin")])])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{ name: 'updatePassword' }}},[_c('a',[_vm._v("Update Password")])])],1),(_vm.id != '0001' && _vm.role == 1)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'settingPage' }}},[_c('a',[_vm._v("Setting")])])],1):_vm._e(),_c('li',{staticClass:"border"},[_c('a',{staticClass:"btn-cursor",on:{"click":_vm.logoutUser}},[_c('i',{staticClass:"fa-solid fa-right-from-bracket"}),_vm._v(" Logout")])])])]),(_vm.id != '0001')?_c('div',{staticClass:"hamburger",class:{ open: _vm.isMenuOpen },on:{"click":_vm.toggleMenu}},_vm._l((3),function(index){return _c('div',{key:index,staticClass:"hamburger-line"})}),0):_vm._e()])]),(_vm.id != '0001')?_c('div',{staticClass:"nav-menu",class:{ open: _vm.isMenuOpen }},[_c('ul',{staticClass:"nav-menu-list"},[_c('li',{staticClass:"dropdown",class:{ active: _vm.$route.name == 'dashboard' }},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('a',{staticClass:"dd-hover"},[_c('i',{staticClass:"fa-solid fa-house"}),_vm._v("Dashboard")])])],1),(_vm.role == 1)?_c('li',{staticClass:"dropdown",class:{ active: _vm.$route.name == 'adminList' }},[_c('router-link',{attrs:{"to":{ name: 'adminList' }}},[_c('a',{staticClass:"dd-hover"},[_c('i',{staticClass:"fa-solid fa-users-gear"}),_vm._v("Admins")])])],1):_vm._e(),_c('li',{staticClass:"dropdown",class:{
                    active:
                        _vm.$route.name == 'periodList' ||
                        _vm.$route.name == 'gradeList' ||
                        _vm.$route.name == 'holidayList' ||
                        _vm.$route.name == 'paymentMethodList' ||
                        _vm.$route.name == 'teacherTypeList',
                }},[_c('a',{staticClass:"dropdown-menu",on:{"click":_vm.toggleDropdown}},[_c('i',{staticClass:"fa-solid fa-gears"}),_vm._v("Master")]),_c('ul',{staticClass:"dropdown-item dropdown_menu--animated dropdown_animation",class:{ show: _vm.isDropdownOpen }},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'periodList' }}},[_c('a',[_vm._v("Academic Period List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'gradeList' }}},[_c('a',[_vm._v("Grade List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'holidayList' }}},[_c('a',[_vm._v("Holiday List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'paymentMethodList' }}},[_c('a',[_vm._v("Payment Method List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'teacherTypeList' }}},[_c('a',[_vm._v("Teacher Type List")])])],1)])]),_c('li',{staticClass:"dropdown",class:{
                    active:
                        _vm.$route.name == 'classList' ||
                        _vm.$route.name == 'gradeFeeList' ||
                        _vm.$route.name == 'timetableList' ||
                        _vm.$route.name == 'timetableMetaList' ||
                        _vm.$route.name == 'subjectList' ||
                        _vm.$route.name == 'campusGradeList' ||
                        _vm.$route.name == 'reportCardItemList',
                }},[_c('a',{staticClass:"dropdown-menu",on:{"click":_vm.toggleDropdown}},[_c('i',{staticClass:"fa-solid fa-chalkboard-user"}),_vm._v("Grade")]),_c('ul',{staticClass:"dropdown-item dropdown_menu--animated dropdown_animation",class:{ show: _vm.isDropdownOpen }},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'campusGradeList' }}},[_c('a',[_vm._v("Campus Grade List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'gradeFeeList' }}},[_c('a',[_vm._v("Grade Fee List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'classList' }}},[_c('a',[_vm._v("Class List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'subjectList' }}},[_c('a',[_vm._v(" Subject List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'timetableMetaList' }}},[_c('a',[_vm._v("Timetable Meta List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'timetableList' }}},[_c('a',[_vm._v("TimeTable List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'reportCardItemList' }}},[_c('a',[_vm._v("Report Card Item List")])])],1)])]),_c('li',{staticClass:"dropdown",class:{
                    active:
                        _vm.$route.name == 'teacherList' ||
                        _vm.$route.name == 'attendanceTeacherList' ||
                        _vm.$route.name == 'subjectTeacher' ||
                        _vm.$route.name == 'salaryList' ||
                        _vm.$route.name == 'courseList',
                }},[_c('a',{staticClass:"dropdown-menu",on:{"click":_vm.toggleDropdown}},[_c('i',{staticClass:"fa-solid fa-people-roof"}),_vm._v("Teachers")]),_c('ul',{staticClass:"dropdown-item dropdown_menu--animated dropdown_animation",class:{ show: _vm.isDropdownOpen }},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'teacherList' }}},[_c('a',[_vm._v("Teacher List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'subjectTeacherList' }}},[_c('a',[_vm._v("Subject Assign List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'courseList' }}},[_c('a',[_vm._v("Course List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'attendanceTeacherList' }}},[_c('a',[_vm._v("Attendance List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'salaryList' }}},[_c('a',[_vm._v("Salary List")])])],1),(_vm.$store.state.setting.teacherChatApproval)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'chatRequestList' }}},[_c('a',[_vm._v("Chat Request List")])])],1):_vm._e()])]),_c('li',{staticClass:"dropdown",class:{
                    active:
                        _vm.$route.name == 'parentList' ||
                        _vm.$route.name == 'studentList' ||
                        _vm.$route.name == 'attendanceStudentList' ||
                        _vm.$route.name == 'gradingList' ||
                        _vm.$route.name == 'itemGradingList',
                }},[_c('a',{staticClass:"dropdown-menu",on:{"click":_vm.toggleDropdown}},[_c('i',{staticClass:"fa-solid fa-people-line"}),_vm._v("Students")]),_c('ul',{staticClass:"dropdown-item dropdown_menu--animated dropdown_animation",class:{ show: _vm.isDropdownOpen }},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'parentList' }}},[_c('a',[_vm._v(" Parent List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'studentList' }}},[_c('a',[_vm._v(" Student List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'gradingList' }}},[_c('a',[_vm._v(" Grading List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'attendanceStudentList' }}},[_c('a',[_vm._v("Attendance List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'itemGradingList' }}},[_c('a',[_vm._v(" Report Card Item Grading List")])])],1)])]),(_vm.role == 1)?_c('li',{staticClass:"dropdown",class:{
                    active:
                        _vm.$route.name == 'paymentList' ||
                        _vm.$route.name == 'inOutcomeList' ||
                        _vm.$route.name == 'itemList',
                }},[_c('a',{staticClass:"dropdown-menu",on:{"click":_vm.toggleDropdown}},[_c('i',{staticClass:"fa-solid fa-coins"}),_vm._v("Finance")]),_c('ul',{staticClass:"dropdown-item dropdown_menu--animated dropdown_animation",class:{ show: _vm.isDropdownOpen }},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'itemList' }}},[_c('a',[_vm._v("Item List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'paymentList' }}},[_c('a',[_vm._v("Payment List")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'transactionList' }}},[_c('a',[_vm._v("Transaction List")])])],1)])]):_vm._e(),_c('li',{staticClass:"dropdown",class:{active:_vm.$route.name == 'noticeList' || _vm.$route.name == 'leaveList'}},[_c('a',{staticClass:"dropdown-menu",on:{"click":_vm.toggleDropdown}},[_c('i',{staticClass:"fa-solid fa-bullhorn"}),_vm._v("Notices")]),_c('ul',{staticClass:"dropdown-item dropdown_menu--animated dropdown_animation",class:{ show: _vm.isDropdownOpen }},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'noticeList' }}},[_vm._v("Notice List")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'leaveList' }}},[_vm._v("Leave List")])],1)])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa-solid fa-caret-down"})])
}]

export { render, staticRenderFns }