import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "reportCardItemDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      reportCardItemInfo: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    async getReportCardItemDetail() {
      try {
        this.isLoading = true;

        const response = await axios.get("/reportCardItem/detail/" + this.$route.params.id);

        if (response.data != undefined) {
          this.reportCardItemInfo = response.data;
        }
      } catch (error) {
        if (error.response && error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        console.error("Report Card Item Detail API Error", error);
      } finally {
        this.isLoading = false;
      }
    },

    showList() {
      this.$router.push({
        path: "/reportCardItem/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getReportCardItemDetail();
  },
};
