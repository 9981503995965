import Vue from 'vue';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../../store';
import axios from "axios";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "ReportCardItemCreate",
  components: {
    MainLayout,
  },

  data() {
    return {
      inputData: {
        item_name: null,
        description: null
      },
      editData: {
        item_name: null,
        description: null
      },
      reportCardItemInfo: [],
      requireErrMsg: '',
      submitted: false,
    };
  },

  validations: {
    inputData: {
      item_name: {
        required,
        maxLength: maxLength(255)
      },
      description: {
        maxLength: maxLength(1000)
      }
    },
  },

  methods: {
    reset() {
      if (this.editData.item_name != null || this.editData.description != null) {
        this.reportCardItemInfo.push(Vue.util.extend({}, this.editData));
        this.editData = {};
      }
      this.inputData = {};
    },

    cloneForm() {
      this.editData = {};
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.reportCardItemInfo.push(Vue.util.extend({}, this.inputData));
      this.inputData = {
        item_name: null,
        description: null
      };
      this.submitted = false;
    },

    removeClone(index) {
      Vue.delete(this.reportCardItemInfo, index);
    },

    editClone(index) {
      if (this.inputData.item_name != null) {
        this.reportCardItemInfo.push(Vue.util.extend({}, this.inputData));
      }
      let data = this.reportCardItemInfo[index];
      this.editData.item_name = data.item_name;
      this.editData.description = data.description;
      this.inputData = this.reportCardItemInfo[index];
      Vue.delete(this.reportCardItemInfo, index);
    },

    create() {
      this.requireErrMsg = '';
      if (this.reportCardItemInfo.length <= 0) {
        this.requireErrMsg = 'Please Add Report Card Item Data';
        return;
      }
      axios.post('reportCardItem/create', {
        reportCardItemInfo: this.reportCardItemInfo,
        campus_id: store.state.user.campus_id,
        login_id: store.state.user.id,
      })
        .then(res => {
          if (res.status == 200) {
            this.$notification.success("Report Card Item Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'reportCardItemList'
            })
          } else if (res.status == 201) {
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.$notification.error("Something Wrong And Check Your Data That Already Exist or Not", {
              timer: 3,
              position: "bottomCenter"
            });
          }
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        })
    }
  }
};
