import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from '../../../store';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "itemGradingDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            itemGradingInfo: {},
            academicYearArr: null,
            gradingArr: null,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            itemGradingData: {},
            itemArr: [],
            academicYear: '',
            gradeTotal: 0,
        };
    },

    methods: {
        getAcademicYear() {
            axios.get('getAcademicYear', {
                params: {
                    campus_id: store.state.user.campus_id
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select'
                    }];
                    this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        async getGradingDetail() {
            try {
                this.isLoading = true;

                const response = await axios.get("/itemGrading/detail/" + this.$route.params.id);
                if (response.data[0] != undefined) {
                    this.itemGradingInfo = response.data[0];
                    this.itemGradingData = response.data;

                    this.selectedClass = this.itemGradingInfo.class_id;
                    await this.getReportCardItemList();
                }
            } catch (error) {
                if (error.response && error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                console.error("Item Grading Detail API Error", error);
            } finally {
                this.isLoading = false;
            }
        },

        showList() {
            this.$router.push({
                path: "/itemGrading/list"
            });
        },

        async getThisAcademicYear() {
            try {
                const response = await axios.get('period/getThisPeriod')
                if (response) {
                    this.academicYear = response.data;
                    this.academic_year = this.academicYear.id;
                }
            } catch (error) {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            }
        },

        async getReportCardItemList() {
            axios
                .get("/reportCardItem/list")
                .then((response) => {
                    if (response.data && response.data.data.length > 0) {
                        this.itemArr = response.data.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Report Card Item API Error", error);
                });
        },
    },

    mounted() {
        this.isLoading = true;
        this.getAcademicYear();
        this.getGradingDetail();
    },
};
