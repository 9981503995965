import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import store from '../../../store';
import {
  required, maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "itemGradingCreate",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      classData: {},
      selectedClass: 0,
      classArr: [],
      studentNameArr: [],
      academic_year: '',
      academicYear: '',
      class_id: '',
      gradingSelected: 0,
      month_id: 0,
      student_id: 0,
      academicYearArr: null,
      gradingArr: null,
      submitted: false,
      items: [],
      comment: [],
      requireErrMsg: [],
      itemErrMsg: '',
      commentMsg: [],
      msgCount: 0,
      id: 0,
      cmt: 0,
      cmtCount: 0,
      isError: false,
      noStudentMsg: '',
      reportCardMonth: [],
      itemArr: [],
      remark: '',
    };
  },

  validations: {
    class_id: {
      required
    },
    academic_year: {
      required
    },
    student_id: {
      required
    },
    month_id: {
      required,
      maxLength: maxLength(100)
    },
    items: {
      $each: {
        required,
        maxLength: maxLength(10)
      }
    },
    comment: {
      $each: {
        maxLength: maxLength(1000)
      }
    }
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Academic Year'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
          this.getClass(this.selectedYear);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.submitted = false;
      this.$set(this, fieldName, val);
      if (fieldName == "academic_year") {
        this.isLoading = true;
        this.classArr = [];
        this.studentNameArr = [];
        this.requireErrMsg = [];
        this.items = [];
        this.comment = [];
        this.commentMsg = [];
        this.student_id = 0;
        this.class_id = 0;
        this.month_id = 0;
        this.selectedClass = 0;
        this.getClass(val);
      }
      if (fieldName == "class_id") {
        this.isLoading = true;
        this.studentNameArr = [];
        this.requireErrMsg = [];
        this.items = [];
        this.comment = [];
        this.commentMsg = [];
        this.month_id = 0;
        this.student_id = 0;
        this.getStudentName(val);
        this.getReportCardMonth();
      }
    },

    getClass(academic_year) {
      axios.get("class/classList/" + academic_year, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEventItem(val, index) {
      if (val === null || val === '') {
        this.requireErrMsg[index] = 'Please Enter Grading Mark.';
      } else {
        this.requireErrMsg[index] = '';
      }
    },

    getStudentName($classId) {
      axios.get("student/getStudentName/" + $classId, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          if (res.data.length > 0) {
            let $defaultSelectOption = [{
              'id': 0,
              'text': 'Select Student Name'
            }];
            this.studentNameArr = $defaultSelectOption.concat(res.data);
            this.noStudentMsg = '';
            this.isLoading = false;
          } else {
            this.studentNameArr = [];
            this.noStudentMsg = 'No student for this class.'
            this.isLoading = false;
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReportCardMonth() {
      axios.get('/itemGrading/getReportCardMonth')
        .then(res => {
          this.reportCardMonth = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReportCardItemList() {
      axios.get("/reportCardItem/list")
        .then((response) => {
          if (response.data && response.data.data.length > 0) {
            this.itemArr = response.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Report Card Item API Error", error);
        });
    },

    confirmRegister() {
      this.msgCount = 0;
      this.cmtCount = 0;
      this.id = 0;
      this.submitted = true;
      this.isLoading = true;
      this.isError = false;

      if (this.$v.$invalid || this.class_id == '' || this.class_id == 0 || this.academic_year == '' || this.academic_year == 0 || this.student_id == '' || this.student_id == 0 || this.isError) {
        if (this.itemArr.length > 0) {
          this.itemArr.forEach((value, index) => {
            this.cmt = this.comment[index] ? this.comment[index].length : 0;
            if (this.cmt > 1000) {
              this.commentMsg[index] = 'Grading Comment';
              this.cmtCount++;
            } else {
              this.commentMsg[index] = '';
            }
            if (this.items[index] == undefined || this.items[index] == 0) {
              this.requireErrMsg[index] = 'Please Enter Grading Mark.';
              this.msgCount++;
            } else if (this.items[index].length > 10) {
              this.requireErrMsg[index] = "Grading Mark must be at most 10 characters.";
              this.msgCount++;
            } else {
              this.requireErrMsg[index] = '';
            }
          });
          this.itemErrMsg = '';
        } else {
          this.itemErrMsg = "Report Card Item Grading Data doesn't exist";
        }

        if (this.msgCount > 0 || this.cmtCount > 0 || this.itemErrMsg != '' || this.isError) {
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        return;
      } else {
        if (this.itemArr.length > 0) {
          this.itemArr.forEach((value, index) => {
            this.cmt = this.comment[index] ? this.comment[index].length : 0;
            if (this.cmt > 1000) {
              this.commentMsg[index] = 'Grading Comment';
              this.cmtCount++;
            } else {
              this.commentMsg[index] = '';
            }
            if (this.items[index] == undefined || this.items[index] == 0) {
              this.requireErrMsg[index] = 'Please Enter Grading Mark.';
              this.msgCount++;
            } else if (this.items[index].length > 10) {
              this.requireErrMsg[index] = "Grading Mark must be at most 10 characters.";
              this.msgCount++;
            } else {
              this.requireErrMsg[index] = '';
            }
          });
          this.itemErrMsg = '';
        } else {
          this.itemErrMsg = "Report Card Item Grading Data doesn't exist";
        }
        if (this.msgCount > 0 || this.cmtCount > 0 || this.itemErrMsg != '') {
          this.isLoading = false;
          return;
        }
      }
      this.register();
    },

    register() {
      var request = {
        academic_year: this.academic_year,
        class_id: this.class_id,
        student_id: this.student_id,
        month_id: this.month_id,
        grading: this.items,
        gradeComments: this.comment,
        reportCardItems: this.itemArr,
        campus_id: store.state.user.campus_id,
        created_id: store.state.user.id
      }

      axios.post("/itemGrading/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.status == 200) {
            this.isLoading = false;
            this.$notification.success("Item Grading Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "itemGradingList"
            });
          }
          else if (response.data.status == 201) {
            this.isLoading = false;
            this.$notification.error("Item Grading data is already exist.", {
              timer: 3,
              position: "bottomCenter",
            });
          } else {
            this.isLoading = false;
            this.$notification.error("something went wrong", {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Item Grading Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/itemGrading/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getAcademicYear();
    this.getReportCardMonth();
    this.getReportCardItemList();
  },
};
