import { render, staticRenderFns } from "./StudentProfile.vue?vue&type=template&id=2c493962"
import script from "../../../controllers/custom/student/student-profile.js?vue&type=script&lang=js&external"
export * from "../../../controllers/custom/student/student-profile.js?vue&type=script&lang=js&external"
import style0 from "./StudentProfile.vue?vue&type=style&index=0&id=2c493962&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports