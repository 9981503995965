import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";

export default {
  name: "reportCardItemList",
  components: {
    MainLayout,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      searchItemName: null,
      reportCardItemData: {},
    };
  },

  methods: {
    getReportCardItem(page = 1) {
      axios.get('reportCardItem/list?page=' + page)
        .then(res => {
          this.reportCardItemData = res.data;
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchItemName = null;
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('reportCardItem/search?page=' + page, {
        params: {
          item_name: this.searchItemName
        }
      })
        .then((res) => {
          this.reportCardItemData = res.data;
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    showCreate() {
      this.$router.push({
        path: "/reportCardItem/create"
      });
    },

    showDetail(id) {
      this.$router.push({
        name: "reportCardItemDetail",
        params: {
          id: id,
        }
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "reportCardItemEdit",
        params: {
          id: id,
        }
      });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteReportCardItem(id);
      } else {
        console.log("no");
      }
    },

    deleteReportCardItem(id) {
      this.isLoading = true;
      axios.delete("/reportCardItem/delete/" + id)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getReportCardItem();
          } else {
            this.$notification.error("Report Card Item is already deleted.", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "reportCardItemList"
            });
            this.getReportCardItem();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Report Card Item Delete Error->", error.response.data);
        });
    },

  },

  mounted() {
    this.isLoading = true;
    this.getReportCardItem();
  },
};
