import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from '../../../store';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "gradingDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            gradingInfo: {},
            academicYearArr: null,
            gradingArr: null,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            gradeData: {},
            subjectArr: [],
            academicYear: '',
            gradeTotal: 0,
        };
    },

    methods: {
        getAcademicYear() {
            axios.get('getAcademicYear', {
              params: {
                  campus_id: store.state.user.campus_id
              }
          })
                .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
                }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                });
        },
        
        async getGradingDetail() {
            try {
                this.isLoading = true;
        
                const response = await axios.get("/grading/detail/" + this.$route.params.student_id, {
                    params: {
                        campus_id: store.state.user.campus_id,
                        exam_id: this.$route.params.exam_id
                    }
                });
        
                if (response.data[0] != undefined) {
                    this.gradingInfo = response.data[0];
                    this.gradeData = response.data;
        
                    this.selectedClass = this.gradingInfo.class_id;
                    await this.getSubjectByClassId(this.selectedClass);
                    var gradeTotal = 0;
                    var isChar = false;
                    this.gradeData.forEach(gradeData => {
                        if (gradeData.grading == null || !isNaN(parseInt(gradeData.grading))) {
                            gradeTotal += gradeData.grading == null ? 0 : (parseInt(gradeData.grading)
                                            ? parseInt(gradeData.grading)
                                            : parseFloat(gradeData.grading));
                        } else {
                            isChar = true;
                            return;
                        }
                    });
                    this.gradeTotal = isChar ? '-' : gradeTotal;
                }
            } catch (error) {
                if (error.response && error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                console.error("Grading Detail API Error", error);
            } finally {
                this.isLoading = false;
            }
        },

        showList() {
            this.$router.push({
                path: "/grading/list"
            });
        },

        async getThisAcademicYear() {
            try {
            const response = await axios.get('period/getThisPeriod')
            if (response) {
                this.academicYear = response.data;
                this.academic_year = this.academicYear.id;
            }
         } catch (error)  {
              if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.isLoading = false;
            }
        },

        async getSubjectByClassId(classId) {
            try {
                this.isLoading = true;
                await this.getThisAcademicYear();
                const response = await axios.get("/class/subjectByClassId/" + classId, {
                    params: {
                        campus_id: store.state.user.campus_id,
                        acd_year_id: this.academic_year
                    }
                });
        
                if (response.data) {
                    this.subjectArr = response.data.subjectArr[0];
                }
            } catch (error) {
                if (error.response && error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                console.error("Subject API Error", error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    
    mounted() {
        this.isLoading = true;
        this.getAcademicYear();
        this.getGradingDetail();
    },
};
